import $ from './dom'
import { delay, toTop } from './helpers'

/**
 * Handle page route updates.
 *
 * @param {string} section
 * @param {boolean} updateUrl
 * @param {boolean} focusSection
 */
function Route(section, updateUrl, focusSection) {
    if ($(`.${section}`).has('is-active')) return

    const url = section !== 'home' ? section : '.'
    const sectionName =  section !== 'home' ? section.charAt(0).toUpperCase() + section.slice(1) : ''

    toTop()

    // Update url?
    if (updateUrl) {
        history.pushState(section, null, url)
    }

    // Update title
    $('title').html(`Lambry ${sectionName ? '| ' + sectionName : ''}`)

    // Update menu
    $('.header .is-active').remove('is-active')
    $(`.header .nav-${section}`).add('is-active')

    // Fade out items
    $(`.section .is-visible`).remove('is-visible')

    // Update active section
    delay(section => {
        $('.section').remove('is-active')
        $(`.${section}`).add('is-active')
    }, section, updateUrl ? 300 : 10)

    // Fade in section items
    delay(section => {
        $(`.${section} .tile`).each((element, index) => {
            setTimeout(() => element.add('is-visible'), updateUrl ? index * 75 : index * 25)
        })

        // Focus section?
        if (focusSection) {
            $(`.${section} .tile:first-child`).node().focus({ preventScroll: false })
        }
    }, section, updateUrl ? 400 : 50)
}

export default function page(section, updateUrl = true, focus = false) {
    return new Route(section, updateUrl, focus)
}
