/**
 * Dom helper function, i.e. jQuery.
 *
 * @param {mixed} selector
 */
function Dom(selector) {
    this.el = typeof selector === 'string' ? document.querySelectorAll(selector) : selector

    // API methods
    this.find = selector => {
        const element = this.node()

        this.el = element.querySelectorAll(selector)

        return this
    }

    this.add = selector => {
        const elements = this.nodes()

        elements.forEach(el => el.classList.add(selector))

        return this
    }

    this.remove = selector => {
        const elements = this.nodes()

        elements.forEach(el => el.classList.remove(selector))

        return this
    }

    this.set = selector => {
        const element = this.node()

        return element.classList = selector
    }

    this.has = selector => {
        const element = this.node()

        return element.classList.contains(selector)
    }

    this.on = (event, callback, prevent = true) => {
        const events = event.split(' ')
        const elements = this.nodes()

        elements.forEach(element => events.forEach(event => this.event(element, event, callback, prevent)))
    }

    this.each = callback => {
        const elements = this.nodes()

        elements.forEach((element, index) => {
            callback(new Dom(element), index)
        })
    }

    this.val = () => {
        const element = this.node()

        return element.value
    }

    this.attr = (name, value) => {
        const element = this.node()

        if (value === undefined) {
            return element.element.getAttribute(name)
        }

        if (value === false) {
            element.removeAttribute(name)
        } else {
            element.setAttribute(name, value)
        }

        return this
    }

    this.html = (html = null) => {
        const element = this.node()

        if (! html) {
            return element.innerHTML
        }

        element.innerHTML = html

        return this
    }

    this.before = html => {
        const element = this.node()

        element.insertAdjacentHTML('beforebegin', html)
    }

    this.after = html => {
        const element = this.node()

        element.insertAdjacentHTML('afterend', html)
    }

    this.append = html => {
        const element = this.node()

        element.appendChild(html)
    }

    // Internal helpers
    this.node = () => {
        return this.el instanceof NodeList ? this.el[0] : this.el
    }

    this.nodes = () => {
        return this.el instanceof NodeList ? this.el : [this.el]
    }

    this.event = (element, event, callback, prevent) => {
        element.addEventListener(event, e => {
            if (prevent) {
                e.preventDefault()
                e.stopPropagation()
            }

            callback(e)
        })
    }

    return this
}

export default function $(selector) {
    return new Dom(selector)
}
