import $ from './utilities/dom'
import page from './utilities/route'
import modal from './utilities/modal'
import { path, delay, theme } from './utilities/helpers'

// Register service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/sw.js')
        .then(() => {
            console.info('Service worker successfully registered.')
        })
        .catch(error => {
            console.error('Service worker registration failed.', error)
        })
}

// Set start page
page(path(), false)

// Update page on navigation item click
$('.route').on('click', ({ currentTarget, clientX, clientY }) => {
    const section = currentTarget.getAttribute('href').substring(1) || 'home'
    const focus = ! clientX && ! clientY

    page(section, true, focus)
})

// Update page on browser navigation
$(window).on('popstate', ({ state }) => page(state || 'home', false))

// Open modal previews
$('.preview').on('click', ({ currentTarget, ctrlKey, metaKey }) => {
    if (ctrlKey || metaKey) return window.open(currentTarget.href)
    
    modal(currentTarget)
})

// Toggle the site theme
$('.theme-toggle').on('click', () => {
    $('body').set(theme())
})

// Show footer
delay(() => $('.footer').add('is-visible'), null, 600)
