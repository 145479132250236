import $ from './dom'

// Close modal preview
$('.modal-close').on('click', () => {
    $('.modal')
        .remove('is-visible')
        .find('.modal-iframe')
        .attr('src', '')
})

$(document).on('keydown', ({ code }) => {
    if (code === 'Escape' && $('.modal').has('is-visible')) {
        $('.modal-close').node().click()
    }
}, false)

// Open modal preview
export default function modal(element) {
    $('.modal')
        .add('is-visible')
        .find('.modal-iframe')
        .attr('src', element.getAttribute('href'))

    $('.modal-title').html($(element).find('.tile-title').html())
}
