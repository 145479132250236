import $ from './dom'

// Get the current path
export function path() {
    return window.location.pathname.split('/')[1] || 'home'
}

// Theme the page theme
export function theme() {
    const body = $('body')

    let isDark = body.has('theme-dark')

    if (! isDark && ! body.has('theme-light')) {
        isDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    }
    
    return isDark ? 'theme-light' : 'theme-dark'
}

// Delay a function call
export function delay(fn, section, time) {
    setTimeout(() => fn(section), time)
}

// Scroll to top
export function toTop() {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
}
